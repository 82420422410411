<template>
    <modal ref="modalAgregarEtapa" titulo="Añadir etapa" tamano="modal-lg" adicional="Guardar" @adicional="agregarEtapa">
        <div class="container add-phase">
            <!-- Select phase -->
            <div class="row justify-content-center my-3">
                <div class="col-12 col-lg-6">
                    <p class="f-10 mb-1">Etapa</p>
                    <el-select v-model="modelEtapa" placeholder="Seleccionar etapa" size="small" class="w-100" @change="getConfiguraciones">
                        <el-option v-for="etapa in etapasSelect" :key="etapa.id" :disabled="etapas.some( el => el.id == etapa.id )" :label="etapa.etapa" :value="etapa.id" />
                    </el-select>
                </div>
            </div>
            <!-- configuration list -->
            <div class="row justify-content-center my-4 list-config">
                <p class="col-12 col-lg-11 f-10 mb-1">Lista de configuraciones</p>
                <div class="col-12 col-lg-11">
                    <div class="shadow29 br-5 p-2">
                        <div class="bg-f5 br-5 py-2">
                            <div class="row mx-0">
                                <div v-for="(data) in configuracionesSeleccionadas" :key="data.id" class="col-3 px-2">
                                    <indicador-like-tag :name="data.nombre" :id="data.id" @deleteItem="deleteItem(data.id)" />
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center my-3">
                            <div class="col-12 col-lg-6 icon-option">
                                <p class="f-10 mb-1">Añadir configuraciones en la etapa:</p>
                                <el-select v-model="modelConfiguraciones" no-data-text="Seleccione una etapa" filterable placeholder="Seleccionar" size="small" class="w-100">
                                    <el-option v-for="configuracion in configuraciones"
                                        :key="configuracion.id"
                                        :label="configuracion.nombre"
                                        :value="configuracion.id"
                                        :disabled="configuracion.disabled"
                                    />
                                </el-select>
                                <i class="icon-plus-circle f-30 cr-pointer" @click="addConfigItem" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
            id_producto: '',
            id_oportunidad: '',
            modelEtapa: '',
            modelConfiguraciones: '',
            configuraciones: [],
            configuracionesSeleccionadas: [],
            tabla:''
        }
    },
    computed: {
        ...mapGetters({
            etapas: 'compras/ordenes_compra/etapas',
            etapasSelect: 'obras/informacion/etapas',
            configuracionesEtapas: 'almacen/stages/stagesConfigurations',
        }),
    },
    methods: {
        ...mapActions({
            obtenerEtapas: 'compras/ordenes_compra/Action_get_etapas',
            Action_get_configurations: 'almacen/stages/Action_get_configurations',
            Action_create_etapa_oportunidad: 'oportunidades/oportunidades/Action_create_etapa_oportunidad',
            obtenerConfiguracionesOportunidad: 'oportunidades/oportunidades/Action_get_configuraciones_oportunidad',
        }),

        async toggle(id_producto, id_oportunidad, tabla){
            this.tabla = tabla
            if(id_producto && id_oportunidad){
                this.id_producto = id_producto
                this.id_oportunidad = id_oportunidad

                await this.obtenerEtapas({
                    id_producto,
                    id_ops: id_oportunidad,
                    tabla
                })
            }
            this.cleanFields()
            this.$refs.modalAgregarEtapa.toggle()
        },
        async getConfiguraciones(id){
            await this.Action_get_configurations({id_etapa: id})
            this.modelConfiguraciones = ''
            this.configuraciones = this.configuracionesEtapas.map( e => ( {...e, disabled: true } ))
            this.configuracionesSeleccionadas = this.configuraciones
        },
        async agregarEtapa(){
            if (this.configuracionesSeleccionadas.length) {
                this.$refs.modalAgregarEtapa.toggle()
                const payload = { id_configuracion: this.configuracionesSeleccionadas.map( e => e.id) }
                await this.Action_create_etapa_oportunidad({id_oportunidad: this.id_oportunidad, id_producto: this.id_producto, payload, tabla: this.tabla })
                await this.obtenerConfiguracionesOportunidad({id_producto: this.id_producto, id_oportunidad: this.id_oportunidad, tabla: this.tabla})
                this.$emit('getEtapaEquipo')
                this.cleanFields()
            }
        },
        deleteItem(id){
            this.configuracionesSeleccionadas = this.configuracionesSeleccionadas.filter(f => f.id != id)
            this.configuraciones.map(e => {
                if (e.id == id) e.disabled = false
            })
        },
        addConfigItem(){
            this.configuraciones.map(f => {
                if (f.id == this.modelConfiguraciones) {
                    this.configuracionesSeleccionadas.push(f)
                    f.disabled = true
                } return f
            })
            this.modelConfiguraciones = ''
        },
        cleanFields(){
            this.modelEtapa = ''
            this.modelConfiguraciones = ''
            this.configuracionesSeleccionadas = []
        }
    },
}
</script>

<style lang="scss" scoped>
.add-phase{
    .bg-d4{
        background: #d4d4d4;
    }
    .list-config{
        i.icon-plus-circle{
            position: absolute; top: 12px;
        }
    }
}
</style>
