<template>
    <section class="op-ver-productos mt-3 overflow-auto custom-scroll container-fluid pt-3">
        <div class="row">
            <div class="col-12 col-lg-8">
                <!-- info principal -->
                <div class="row">
                    <div class="col-12 pl-5">
                        <div class="bg-f5 border br-8">
                            <div class="row mx-0 position-ab contenedor-info">
                                <img :src="oportunidadesInformacion.img | helper-storage-sandbox" alt="" class="img-logo-op wh-106 rounded-circle position-relative">
                                <div class="col-12 col-md col-xl-7 h-100 d-middle">
                                    <div>
                                        <p class="f-18 f-700 mb-1">{{ oportunidadesInformacion.nombre_oportunidad }}</p>
                                        <p class="f-14 f-500"> {{ oportunidadesInformacion.cliente }} </p>
                                    </div>
                                </div>
                                <div class="col-12 col-md col-xl-3 h-100 d-middle justify-content-end">
                                    <div>
                                        <indicador-valores titulo="Probabilidad" :valor="oportunidadesInformacion.probabilidad" porcentaje class="mb-2" />
                                        <p class="f-14 f-500">
                                            Cierre:
                                            <span class="f-300">
                                                {{ oportunidadesInformacion.cierre | helper-fecha('DD MMM YYYY') }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- equipos cotizados -->
                <titulo-divisor titulo="Equipos cotizados">
                    <div class="row">
                        <div class="col-auto my-auto px-1">
                            <el-tooltip placement="bottom-end" effect="light" visible-arrow popper-class="like-buttons">
                                <template #content>
                                    <p class="p-2 item"
                                        :class="permitAction('oportunidades.equipos.agregar.equipo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('oportunidades.equipos.agregar.equipo', agregarEquipoModelo)"
                                    >Añadir equipo modelo</p>
                                    <p class="p-2 item"
                                        :class="permitAction('oportunidades.equipos.agregar.equipo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('oportunidades.equipos.agregar.equipo', agregarOportunidadEquipo)"
                                    >Cargar de otra oportunidad</p>
                                </template>
                                <button class="btn btn-general f-12">
                                    <p class="d-middle">
                                        Añadir equipo
                                        <i class="icon-menu-down ml-2 f-14" />
                                    </p>
                                </button>
                            </el-tooltip>
                        </div>
                    </div>
                </titulo-divisor>
                <!-- listado equipos -->
                <div class="row">
                    <div v-for="equipo in equipos" :key="equipo.id" class="col-auto" @click="gearSelected = equipo">
                        <img :src="equipo.imagen | helper-storage" alt="" class="wh-110 br-4 cr-pointer" :class="gearSelected.id == equipo.id ? 'border-general2' : 'border-dd2 op-05'">
                    </div>
                </div>
                <!-- info equipo -->
                <div v-if="Object.entries(gearSelected).length" class="row mt-4">
                    <div class="col-12">
                        <div class="shadow border br-4 px-3 py-4 info-equipo">
                            <el-tooltip content="Eliminar equipo" placement="bottom-end" effect="light" visible-arrow>
                                <div class="icon-option eliminar-equipo"
                                    @click="functionPermitAction('oportunidades.equipos.eliminar', deleteEquipo)"
                                >
                                    <i class="icon-trash-can-outline f-23" :class="permitAction('oportunidades.equipos.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" />
                                </div>
                            </el-tooltip>
                            <div class="row">
                                <div class="col-auto">
                                    <img :src="gearSelected.imagen | helper-storage" alt="" class="wh-151 border-dd br-4">
                                </div>
                                <div class="col">
                                    <p class="f-18 f-700">{{ gearSelected.nombre }}</p>
                                    <div class="row my-3">
                                        <div class="col-6 col-lg-6 col-xl-auto mb-2 my-lg-auto">
                                            <div class="d-middle">
                                                <p class="f-500 f-12">
                                                    Precio:
                                                    <span class="f-300">{{formatoMoneda(detalleEtapa.precio)}}</span>
                                                </p>
                                                <!-- <i class="icon-alert text-orange f-18 ml-1" /> -->
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg-6 col-xl-auto mb-2 my-lg-auto">
                                            <p class="f-500 f-12">
                                                Etapas:
                                                <span class="f-300"> {{detalleEtapa.etapas}} </span>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg-6 col-xl-auto mb-2 my-lg-auto">
                                            <p class="f-500 f-12">
                                                Materiales:
                                                <span class="f-300"> {{detalleEtapa.materiales}} </span>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg-6 col-xl-auto mb-2 my-lg-auto">
                                            <p class="f-500 f-12">
                                                Horas hombre:
                                                <span class="f-300"> {{detalleEtapa.horas_hombre}} </span>
                                            </p>
                                        </div>
                                    </div>
                                    <p class="f-12 lh-15">{{ gearSelected.descripcion }}</p>
                                </div>
                            </div>
                            <titulo-divisor titulo="Etapas de equipo" class="mr-0">
                                <div class="row">
                                    <div class="col-auto my-auto px-1 icon-option">
                                        <el-tooltip content="Añadir etapa" placement="bottom" effect="light" visible-arrow popper-class="like-buttons">
                                            <i class="icon-plus-circle f-30" :style="`pointer-events: ${gearSelected ? 'auto': 'none'}`"
                                                :class="permitAction('oportunidades.equipos.agregar.etapa') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                @click="functionPermitAction('oportunidades.equipos.agregar.etapa', addPhase, [gearSelected])"
                                            />
                                        </el-tooltip>
                                    </div>
                                </div>
                            </titulo-divisor>
                            <div class="row">
                                <div class="col-12">
                                    <el-collapse v-model="activeName" class="phases-list">
                                        <el-collapse-item v-for="configuracion in sliceEtapas" :key="configuracion.id" :name="`${configuracion.id}`">
                                            <template slot="title">
                                                <div class="d-flex w-100 f-600">
                                                    <p class="pl-2 text-5d"> {{ configuracion.almacen_etapas.nombre }} </p>
                                                    <div class="d-middle ml-auto icon-option">
                                                        <el-tooltip content="Añadir configuración" effect="light" visible-arrow>
                                                            <i class="f-20 icon-plus-circle"
                                                                :class="permitAction('oportunidades.equipos.agregar.configuracion.etapa') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                                @click.stop="functionPermitAction('oportunidades.equipos.agregar.configuracion.etapa', addConfigurationPhase, [configuracion])"
                                                            />
                                                        </el-tooltip>
                                                        <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                                            <i class="f-20 icon-delete"
                                                                :class="permitAction('oportunidades.equipos.eliminar.configuracion.etapa') ? 'cr-pointer' : 'cr-not-allowed'" 
                                                                @click.stop="functionPermitAction('oportunidades.equipos.eliminar.configuracion.etapa', deletePhase, [configuracion.id])"
                                                            />
                                                        </el-tooltip>
                                                    </div>
                                                </div>
                                            </template>
                                            <div class="row justify-content-center">
                                                <div class="col-11">
                                                    <div v-for="conf in configuracion.almacen_etapas_configuraciones" :key="conf.id" 
                                                    class="shadow29 bg-white text-5d br-4 px-2 py-1 f-14 cr-pointer mb-2"
                                                    @click="verConfigurar(conf)">
                                                        <div class="row f-14 mx-0">
                                                            <div class="col-6 my-auto tres-puntos">
                                                                <p class="tres-puntos f-14"> {{ conf.nombre }} </p>
                                                            </div>
                                                            <div class="col-auto ml-auto">
                                                                <div class="d-flex">
                                                                    <i class="icon-cash-multiple f-18" />
                                                                    <p class="my-auto"> {{ formatoMoneda(conf.valor) }} </p>
                                                                </div>
                                                            </div>
                                                            <div class="col-auto">
                                                                <div class="d-flex">
                                                                    <i class="icon-account-clock f-18" />
                                                                    <p class="my-auto"> {{ conf.horas_hombre }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-collapse-item>
                                    </el-collapse>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <el-pagination
                                        layout="prev, pager, next"
                                        @current-change="paginateEtapas"
                                        :current-page.sync="currentPage"
                                        :page-size="perPage"
                                        :hide-on-single-page="!configuracionesOportunidad.length > 0"
                                        :total="totalPage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Resumen -->
            <opportunity-product-resume :idProduct="gearSelected.id" :tabla="gearSelected.tabla" @reload="getResumes"/>
        </div>
        <!-- partials -->
        <modal-agregar-equipo-modelo ref="modalAgregarEquipoModelo" />
        <modal-agregar-equipo-op ref="modalAgregarEquipoOp" />
        <modal-add-phase ref="modalAddPhase" @getEtapaEquipo="getEtapaEquipo" />
        <modal-add-configuration-phase ref="AddConfigurationPhase" :stage="stageSelected" :producto="gearSelected" :oportunidad="oportunidad" @refreshStages="refreshStages"/>
        <modal-eliminar ref="modalEliminarEquipo" title="Eliminar equipo" mensaje="¿Está seguro que quiere eliminar este equipo?" @delete="eliminarEquipo" />
        <modal-eliminar ref="modalEliminarPhase" title="Eliminar etapa" mensaje="¿Está seguro que quiere eliminar esta etapa?" @delete="deleteEtapa" />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { OpportunityConfigurationFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityConfigurationFactory'

import modalAgregarEquipoModelo from '../../partials/modalAgregarEquipoModelo'
import modalAgregarEquipoOp from '../../partials/modalAgregarEquipoOp'
import modalAddPhase from '../partials/modalAddPhase'
import modalAddConfigurationPhase from '../partials/modalAddConfigurationPhase'
import OpportunityProductResume from './components/OpportunityProductResume'
export default {
    components:{
        OpportunityProductResume,
        modalAgregarEquipoModelo,
        modalAgregarEquipoOp,
        modalAddPhase,
        modalAddConfigurationPhase,
    },
    data(){
        return{
            idOportunidad: this.$route.params.id,
            gearSelected: {},
            checkListIncrementos: [],
            activeName: '',
            currentPage: 1,
            oportunidad: {},
            etapaEliminar: '',
            stageSelected: OpportunityConfigurationFactory.instantiateEmpty(),
            perPage: 6,
            currentPage: 1,
            totalPage: 1,
            sliceEtapas: [],
			payload:{
				id_producto: null,
				id_oportunidad: null,
				tabla: null
			}
        }
    },
    computed: {
        ...mapGetters({
            equipos: 'compras/ordenes_compra/equipos',
            configuracionesOportunidad: 'oportunidades/oportunidades/configuraciones_oportunidad',
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
            detalleEtapa: 'oportunidades/productos/resumen/detalleEtapa',

        })
    },

    async created () {
        this.Action_get_percentage_increases()
        await this.obtenerEquipos({id_oportunidad: this.idOportunidad});
        await this.Action_get_etapas_select(this.idOportunidad)
    },
    watch: {
        configuracionesOportunidad(val) {
            this.currentPage = 1
            this.totalPage = val.length
            this.sliceEtapas = val.length > this.perPage ? this.configuracionesOportunidad.slice(0, this.perPage) : val
        },
        gearSelected (val) {
            if (val.value || val) {
				this.payload = {
					id_producto: val.id ?? val.value.id,
					id_oportunidad: this.idOportunidad,
					tabla: val.tabla ?? val.value.tabla
				}
                Promise.all([
                    this.Action_get_opportunity_product_resume(this.payload),
                    this.obtenerConfiguracionesOportunidad(this.payload),
                    this.Action_get_detalle_etapa(this.payload)
                ])
            }
        }
    },
    methods:{
        ...mapActions({
            obtenerEquipos:'compras/ordenes_compra/Action_get_equipos',
            obtenerConfiguracionesOportunidad: 'oportunidades/oportunidades/Action_get_configuraciones_oportunidad',
            eliminarEtapa: 'oportunidades/oportunidades/Action_delete_etapa_oportunidad',
            Action_delete_equipo_modelo_oportunidad: 'oportunidades/oportunidades/Action_delete_equipo_modelo_oportunidad',
            Action_get_etapas_select: 'obras/informacion/Action_get_etapas',
            Action_get_opportunity_product_resume: 'oportunidades/productos/resumen/Action_get_opportunity_product_resume',
            Action_get_detalle_etapa: 'oportunidades/productos/resumen/Action_get_detalle_etapa',
            Action_get_percentage_increases: 'oportunidades/productos/resumen/Action_get_percentage_increases',
        }),
		async getResumes(){
			await this.Action_get_opportunity_product_resume(this.payload)
		},
        async agregarEquipoModelo(){
            this.$refs.modalAgregarEquipoModelo.toggle()

        },
        async agregarOportunidadEquipo(){
            this.$refs.modalAgregarEquipoOp.toggle()

        },
        deleteEquipo(){
            this.$refs.modalEliminarEquipo.toggle()
        },
        async eliminarEquipo(){
            const payload = {
                idOps:this.idOportunidad,
                producto:this.gearSelected.id,
                tabla:this.gearSelected.tabla
            }
            await this.Action_delete_equipo_modelo_oportunidad(payload)
            await this.obtenerEquipos({id_oportunidad: this.idOportunidad});
            this.gearSelected = {}
        },
        addPhase(item){
            this.$refs.modalAddPhase.toggle(item.id, this.idOportunidad, item.tabla)
        },
        addConfigurationPhase(configuracion){
            this.$refs.AddConfigurationPhase.toggle()
            this.stageSelected = configuracion
        },
        editPhase(data){
            this.$refs.modalEditPhase.toggle(data)
        },
        deletePhase(id_etapa){
            this.etapaEliminar = id_etapa
            this.$refs.modalEliminarPhase.toggle()
        },
        verConfigurar(conf){
            this.$router.push({ name: 'oportunidades.ver.productos.config',
                params: {
                    idOportunidad: this.idOportunidad,
                    idProducto: this.gearSelected.id,
                    idConfiguracion: conf.id_oportunidades_equipos_modelos_etapas_configuraciones,
                    tabla:this.gearSelected.tabla
                }
            })
        },
        async deleteEtapa(){
            await this.eliminarEtapa({id_producto: this.gearSelected.id, id_oportunidad: this.idOportunidad, id_etapa: this.etapaEliminar, tabla:this.gearSelected.tabla})
            await this.obtenerConfiguracionesOportunidad({id_producto: this.gearSelected.id, id_oportunidad: this.idOportunidad, tabla:this.gearSelected.tabla})
            await this.Action_get_opportunity_product_resume(this.payload),
            await this.obtenerConfiguracionesOportunidad(this.payload),
            await this.Action_get_detalle_etapa(this.payload)
        },
        async getEtapaEquipo(){
            await this.Action_get_opportunity_product_resume(this.payload),
            await this.obtenerConfiguracionesOportunidad(this.payload),
            await this.Action_get_detalle_etapa(this.payload)
        },
        async refreshStages(){
            await this.obtenerConfiguracionesOportunidad({id_producto: this.gearSelected.id, id_oportunidad: this.idOportunidad, tabla:this.gearSelected.tabla})

        },
        paginateEtapas(page){
            let fin = page*this.perPage
            let inicio = fin-this.perPage
            fin = fin < this.configuracionesOportunidad.length ? fin : this.configuracionesOportunidad.length
            this.sliceEtapas = this.configuracionesOportunidad.slice(inicio, fin)
        }
    },
}
</script>

<style lang="scss" scoped>
.op-ver-productos{
    height: calc(100vh - 247px);
    .contenedor-info{
        height: 87px;
        .img-logo-op{
            left: -24px;
            top: -10px;
        }
    }
    .info-equipo{
        position: relative;
        .eliminar-equipo{
            position: absolute;
            top: 6px;
            right: 7px;
            z-index: 1;
        }
    }
    .like-input-percentage{
        max-width: 53px;
        min-width: 53px;
    }
    .indicador-mint{
        width: auto !important;
    }
    .card-etapa-general{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
    }
}
</style>