<template>
    <modal ref="modalAgregarConfiguracion" titulo="Añadir configuración" tamano="modal-md" adicional="Añadir" @adicional="AgregarConfiguracion">
        <div class="container add-phase">
            <div class="row my-3">
                <div class="col-12 text-center">
                    <p class="f-12">Etapa: <span class="f-500 f-13">{{ stage.almacen_etapas.nombre }}</span> </p>
                </div>
            </div>
            <div class="row justify-content-center my-3 list-config">
                <div class="col-12 col-lg-10 icon-option">
                    <p class="f-10 mb-1">Seleccione las configuraciones de la etapa:</p>
                    <el-select v-model="valueConfigurations" filterable placeholder="Seleccionar" size="small" class="w-100">
                        <el-option v-for="configuraciones in configuracionesEtapas" :disabled="disabled.some( el => el == configuraciones.id )" :key="configuraciones.id" :label="configuraciones.nombre" :value="configuraciones.id" />
                    </el-select>
                    <i class="icon-plus-circle f-30 cr-pointer" @click="addConfigItem" />
                </div>
            </div>
            <!-- list new configurations -->
            <div class="row justify-content-center mt-3">
                <div class="col-12 col-lg-10">
                    <div class="row mx-n2">
                        <div v-for="data in newListConfig" :key="data.id" class="col-6 px-2">
                            <indicador-like-tag :name="data.nombre" :id="data.id" @deleteItem="deleteItem(data.id)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { OpportunityConfigurationFactory } from '~/domain/entities/oportunidades/productos/factories/OpportunityConfigurationFactory'
export default {
    props: {
        stage: {
            type: Object,
            default: () => OpportunityConfigurationFactory.instantiateEmpty()
        },
        producto: {
            type: Object,
            default: () => ({})
        },
        oportunidad: {
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return {
            valueConfigurations: '',
            newListConfig:[]
        }
    },
    watch: {
       stage: {
           async handler(val){
                this.newListConfig = val.almacen_etapas_configuraciones
                await this.obtenerConfiguracionesOportunidad({id_etapa: val.id_almacen_etapas})
           },
           deep: true,
       }
    },
    computed: {
        ...mapGetters({
            configuracionesEtapas: 'almacen/stages/stagesConfigurations',
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
        }),
        disabled(){
            return this.newListConfig.map( el => el.id )
        }
    },
    methods: {
        ...mapActions({
            obtenerConfiguracionesOportunidad: 'almacen/stages/Action_get_configurations',
            editarEtapa: 'oportunidades/oportunidades/Action_edit_etapa_oportunidad'
        }),

        async toggle(){
            this.newListConfig = this.stage.almacen_etapas_configuraciones
            this.$refs.modalAgregarConfiguracion.toggle()
        },
        async AgregarConfiguracion(){
            const id_configuracion = this.newListConfig.map(conf => conf.id)
            await this.editarEtapa({payload: {id_configuracion, tabla: this.producto.tabla}, id_etapa: this.stage.id, id_producto: this.producto.id, id_oportunidad: this.oportunidadesInformacion.id })
            this.$emit('refreshStages')
            this.toggle()
        },
        deleteItem(id){
            this.newListConfig = this.newListConfig.filter(config => config.id !== id)
        },
        addConfigItem(){
            this.configuracionesEtapas.map(e => {
                if (e.id === this.valueConfigurations) {
                    this.newListConfig.push(e)
                }
            })
            this.valueConfigurations = ''
        }
    }
}
</script>

<style lang="scss" scoped>
.add-phase{
    .bg-d4{
        background: #d4d4d4;
    }
    .list-config{
        i.icon-plus-circle{
            position: absolute; top: 12px;
        }
    }
}
</style>
