<template>
    <div class="col-12 col-lg-4">
        <div class="bg-f9 border p-2 br-8">
            <!-- resumen + cantTotal materiales -->
            <div class="row mx-0">
                <div class="col-12 col-lg-6 my-auto">
                    <p class="f-500">Resúmen</p>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="d-middle justify-content-lg-end">
                        <el-tooltip content="Cantidad total materiales" effect="light" visible-arrow>
                            <i class="icon-format-list-numbered f-20 mr-2" />
                        </el-tooltip>
                        <p class="f-14"> {{ resume.materiales }} </p>
                    </div>
                </div>
            </div>
            <!-- horas adicionales +  horas totales -->
            <div class="row mx-0 my-3">
                <div class="col-12 col-lg-6 my-auto">
                    <p class="f-10">Horas/h adicionales</p>
                        <el-input v-model="resume.horas_adicionales" size="small" class="w-75" :disabled="!(idProduct && permitAction('oportunidades.equipos.agregar.horas.adicionales'))" 
                            :class="permitAction('oportunidades.equipos.agregar.horas.adicionales') ? 'cr-pointer' : 'cr-not-allowed'" 
                            @change="functionPermitAction('oportunidades.equipos.agregar.horas.adicionales', handleSaveAdditionalHours)"
                            />
                </div>
                <div class="col-12 col-lg-6">
                    <p class="f-10 text-lg-right">Horas/h totales</p>
                    <div class="d-middle justify-content-lg-end">
                        <i class="icon-account-clock f-18 mr-2" />
                        <p class="f-14">{{ resume.horas_totales }}</p>
                    </div>
                </div>
            </div>
            <!-- "With popover": option 1 -->
            <div class="row mx-0 my-3">
                <div class="col-12"
                    @click.stop="functionPermitAction('oportunidades.equipos.seleccionar.incrementos.porcentuales')"
                >
                    <p class="f-10">Incrementos porcentuales</p>
                    <el-popover 
                    v-model="visibleIncrements" 
                    placement="bottom" 
                    width="300" 
                    trigger="manual" 
                    popper-class="w-25 p-2"
                    :disabled="!permitAction('oportunidades.equipos.seleccionar.incrementos.porcentuales') ">
                        <el-checkbox-group v-model="checkedIncreases">
                            <el-checkbox v-for="(data, index) in percentage_increases" 
                            :key="index" 
                            class="w-100"
                            :label="data.id"
                            :disabled="selected_increases.includes(data.id)"
                            @change="handleAddIncrease(data.id)"
                            >{{data.nombre}}</el-checkbox>
                        </el-checkbox-group>
                        <button 
                        slot="reference" 
                        class="no-hover w-100 border br-5 bg-white px-3 text-dark-gray" size="mini" 
                        :class="{'is-disabled': !idProduct}" 
                        @click="!idProduct ? () => {} : visibleIncrements = !visibleIncrements"
                        >
                            <p class=" d-middle justify-content-between">
                                <span class="f-12">Añadir</span>
                                <i class="icon-menu-down f-22" />
                            </p>
                        </button>
                    </el-popover>
                </div>
            </div>
            <div class="row mx-0 my-3">
                <div class="col-12">
                    <template v-for="(data, index) in resume.incrementos_porcentuales">
                        <increase 
                        :key="index" 
                        :name="data.nombre" 
                        :percentage="data.porcentaje" 
                        :permiso="'oportunidades.equipos.eliminar.incrementos.porcentuales'"
                        @update="handleUpdateIncrease(data, $event)"
                        @remove="handleRemoveIncrease(data.id)"
                        />
                    </template>
                </div>
            </div>
            <div class="row mx-auto justify-content-end">
                <div class="col-auto">
                    <indicador-valores titulo="Valor total" :valor="resume.valor_ultima_cotizacion" estilo="indicador-mint-ops" moneda class="mb-2" />
                    <p class="font-italic f-10 text-right">Según última cotización</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Increase from './Increase';
export default {
    components: { Increase },
    props: {
        idProduct: {
            type: Number,
        },
        tabla: {
            type: String,
        },
    },
    data(){
        return {
            input: '',
            valueSelect: '',
            visibleIncrements: false,
            checkedIncreases: [],
			idOportunidad: this.$route.params.id,
        }
    },
    computed:{
        ...mapGetters({
            percentage_increases: 'oportunidades/productos/resumen/percentage_increases',
            resume: 'oportunidades/productos/resumen/resume',
        }),
        selected_increases(){
            return this.resume.incrementos_porcentuales.map(el => el.id_incremento)
        }
    },

    watch:{
        selected_increases(val){
            this.checkedIncreases = val;
        },
        handler(val){
            this.resume.horas_adicionales = val.horas_adicionales
        },
        deep: true
    },
    methods: {
        ...mapActions({
            Action_save_percentage_increase: 'oportunidades/productos/resumen/Action_save_percentage_increase',
            Action_update_percentage_increase: 'oportunidades/productos/resumen/Action_update_percentage_increase',
            Action_delete_percentage_increase: 'oportunidades/productos/resumen/Action_delete_percentage_increase',
            Action_save_additional_hours: 'oportunidades/productos/resumen/Action_save_additional_hours',
        }),
        handleUpdateIncrease({ id, id_incremento, porcentaje }, new_porcentaje){
            this.Action_update_percentage_increase({
                id,
                id_configuracion_incrementos_porcentuales: id_incremento,
                id_oportunidad_resumen: this.resume.id,
                porcentaje: new_porcentaje,
                old_porcentaje: porcentaje,
            })
			this.$emit('reload')
        },
        handleRemoveIncrease(id){
            this.Action_delete_percentage_increase(id)
			this.$emit('reload')
        },
        handleAddIncrease(id){
            this.Action_save_percentage_increase({
                id_configuracion_incrementos_porcentuales: id,
                id_oportunidad_resumen: this.resume.id,
            })
			this.$emit('reload')
        },
        async handleSaveAdditionalHours(){
            await this.Action_save_additional_hours({
                id_oportunidad_resumen: this.resume.id,
                horas_adicionales: this.resume.horas_adicionales,
            })
			this.$emit('reload')
        }
    }

}
</script>

<style scoped>
button.is-disabled, button.is-disabled:focus, button.is-disabled:hover,
.el-checkbox.id-disabled, .el-checkbox.is-disabled:focus, .el-checkbox.is-disabled:hover{
    color: var(--color-db) !important;
    cursor: not-allowed;
    background-color: #FFF;
}
</style>