<template>
    <div class="border bg-f5 br-3 row mx-0 py-1 mb-3">
        <div class="col-7 my-auto">
            <p class="tres-puntos f-12"> {{ name }} </p>
        </div>
        <div class="col-5">
            <div class="d-flex justify-content-end">
                <el-input v-model="porcentaje" size="mini" @change="$emit('update', parseFloat(porcentaje))"/>
                <i 
                    class="icon-close-circle ml-2 my-auto" 
                    :class="permitAction('oportunidades.equipos.agregar.etapa') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click="permiso ? functionPermitAction(permiso, () => $emit('remove') ) : $emit('remove')"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: '-',
        },
        permiso: {
            type: String,
            required: false
        },
        percentage: {
            type: Number,
            required: true,
            default: 0
        },
    },
    watch: {
        percentage(val){
            this.porcentaje = val
        }
    },
    created(){
        this.porcentaje = this.percentage
    },
    data(){
        return {
            porcentaje: 0
        }
    }
}
</script>