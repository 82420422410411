import { render, staticRenderFns } from "./modalAddConfigurationPhase.vue?vue&type=template&id=58c75a10&scoped=true&"
import script from "./modalAddConfigurationPhase.vue?vue&type=script&lang=js&"
export * from "./modalAddConfigurationPhase.vue?vue&type=script&lang=js&"
import style0 from "./modalAddConfigurationPhase.vue?vue&type=style&index=0&id=58c75a10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c75a10",
  null
  
)

export default component.exports